import { datadogRum } from "@datadog/browser-rum";
import { initDatadog } from "@/features/Analytics/helpers/datadog";
import { redirectToLoginIfUnauthed } from "@/features/AuthenticationRedirect";
import { reloadIfReactVersionChanged } from "@/features/React18/checkReactVersion";

const appEntryChunkPromise = import(
  /* webpackChunkName: "AppEntry" */ "@/domains/App/components/Entry"
);

datadogRum.addTiming("index.loaded");

async function main() {
  // @NOTE: We must initialize DD sdk here as the user may be redirected to login
  // (before any analytics initialization - that happens in React lifecycle)
  initDatadog();

  const reloaded = reloadIfReactVersionChanged();
  if (reloaded) return;

  const redirected = await redirectToLoginIfUnauthed();
  if (redirected) return;

  await appEntryChunkPromise.then(({ renderApp }) => renderApp());
}

void main();
