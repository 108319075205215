/* eslint-disable */
// @ts-nocheck
import { getEnvironment } from "@/helpers";

const loadSegmentScript = () => {
  // OFFICIAL SEGMENT SNIPPET (prettier-ified)
  // @see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/#step-2-copy-the-segment-snippet
  const segmentKey = getEnvironment("SEGMENT_WRITE_KEY");
  const segmentURL = getEnvironment("SEGMENT_URL");
  const snippetVersion = getEnvironment("SEGMENT_SNIPPET_VERSION");
  !(function () {
    var analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
      if (analytics.invoked)
        window.console &&
          console.error &&
          console.error("Segment snippet included twice.");
      else {
        analytics.invoked = !0;
        analytics.methods = [
          "trackSubmit",
          "trackClick",
          "trackLink",
          "trackForm",
          "pageview",
          "identify",
          "reset",
          "group",
          "track",
          "ready",
          "alias",
          "debug",
          "page",
          "once",
          "off",
          "on",
          "addSourceMiddleware",
          "addIntegrationMiddleware",
          "setAnonymousId",
          "addDestinationMiddleware",
        ];
        analytics.factory = function (e) {
          return function () {
            var t = Array.prototype.slice.call(arguments);
            t.unshift(e);
            analytics.push(t);
            return analytics;
          };
        };
        for (var e = 0; e < analytics.methods.length; e++) {
          var key = analytics.methods[e];
          analytics[key] = analytics.factory(key);
        }
        analytics.load = function (_key, e) {
          var t = document.createElement("script");
          t.type = "text/javascript";
          t.async = !0;
          t.src = segmentURL;
          var n = document.getElementsByTagName("script")[0];
          n.parentNode.insertBefore(t, n);
          analytics._loadOptions = e;
        };
        analytics._writeKey = segmentKey;
        analytics._cdn = "https://sc.brex.com";
        analytics.SNIPPET_VERSION = snippetVersion;
      }
  })();
};

export default loadSegmentScript;
