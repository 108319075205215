/** Used to generate cookies that are shared between dashboard.brex.com and brex.com */
export const getCookieRootDomain = (host: string): string => {
  if (host.startsWith("localhost")) {
    return "localhost";
  }
  if (host.endsWith("staging.brexapps.com")) {
    return ".stg.brex.com";
  }
  if (host.endsWith("brex.com")) {
    return ".brex.com";
  }
  return "";
};
