import { v4 as uuid } from "uuid";

const generateWorkflowId = (): string => {
  const now = new Date().getTime();
  return "wid_" + uuid() + "-" + now;
};

let workflowId = generateWorkflowId();
let lastRouteName: string | undefined = "";

export const getWorkflowId = () => {
  return workflowId;
};

export const maybeUpdateWorkflowId = (routeName?: string) => {
  // The hooks calling this function have optional route/path arguments, so we support undefined paths. In this case, we
  // still refresh the workflow ID if previous and new path are both undefined (equal).
  if (routeName && routeName === lastRouteName) {
    return;
  }
  lastRouteName = routeName;
  workflowId = generateWorkflowId();
};
