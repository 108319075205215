import * as React from "react";
import { useTrackEventOnView } from "@/features/Analytics/hooks/useTrackEventOnView";
import type {
  AllAnalyticsEventsKeys,
  TrackEventProps,
} from "@/features/Analytics/sharedTypes";

export const TrackEventOnView = <TEventName extends AllAnalyticsEventsKeys>({
  children,
  eventName,
  eventProps = {},
  rootMargin,
}: React.PropsWithChildren<
  TrackEventProps<TEventName> & {
    rootMargin?: IntersectionObserverInit["rootMargin"];
  }
>) => {
  const componentRef = React.useRef<HTMLDivElement>(null);

  useTrackEventOnView({
    eventName,
    eventProps,
    elementRef: componentRef,
    rootMargin,
  });

  return <div ref={componentRef}>{children}</div>;
};
