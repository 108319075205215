import qs from "qs";

const marketingAttributionProperties = [
  "ref_code",
  "gclid",
  "group_code",
  "ad_id",
  "adset_id",
  "campaign_id",
  "adgroup_id",
  "feeditem_id",
  "target_id",
  "loc_physical_ms",
  "loc_interest_ms",
  "matchtype",
  "network",
  "device",
  "creative",
  "placement",
  "utm_campaign",
  "utm_medium",
  "cchno",
] as const;

export type ReferralProperties = Partial<{
  [key in (typeof marketingAttributionProperties)[number]]: string;
}>;

export const getReferralProperties = (): ReferralProperties =>
  marketingAttributionProperties.reduce((referralProperties, urlParameter) => {
    const propValue = sessionStorage.getItem(urlParameter);
    return propValue !== null && propValue !== ""
      ? {
          ...referralProperties,
          [urlParameter]: propValue,
        }
      : referralProperties;
  }, {});

export const saveReferralProperties = (): ReferralProperties => {
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const referralState: ReferralProperties =
    marketingAttributionProperties.reduce(
      (referralProperties, urlParameter) => {
        const paramValue = params[urlParameter];
        return paramValue
          ? {
              ...referralProperties,
              [urlParameter]: paramValue,
            }
          : referralProperties;
      },
      {},
    );
  Object.entries(referralState).forEach(([parameter, value]) => {
    sessionStorage.setItem(parameter, value as string);
  });
  return referralState;
};
