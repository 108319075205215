import type { JwtPayload } from "jwt-decode";
import jwtDecode from "jwt-decode";

export const getTokenExpirationTime = (token: string) => {
  const decoded = jwtDecode<JwtPayload>(token);
  return decoded?.exp ? decoded.exp * 1000 : null;
};

export const getAuthOccurredAt = (token: string) => {
  const decoded = jwtDecode<JwtPayload>(token);
  return decoded?.iat ? decoded.iat * 1000 : null;
};

export const getTokenIssuer = (token: string) => {
  const decoded = jwtDecode<JwtPayload>(token);
  return decoded?.iss ? decoded.iss : null;
};

export const getTokenSub = (token: string) => {
  const decoded = jwtDecode<JwtPayload>(token);
  return decoded?.sub ? decoded.sub : null;
};

export const getTokenAcr = (token: string) => {
  const decoded = jwtDecode<JwtPayload & { acr?: string }>(token);
  return decoded?.acr ? decoded.acr : null;
};

export const getFactorVerifiedAt = (token: string) => {
  const decoded = jwtDecode<JwtPayload & { fa_time?: number }>(token);
  return decoded?.fa_time ? decoded.fa_time * 1000 : null;
};
