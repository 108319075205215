import * as React from "react";
import { useTrackEvent } from "@/features/Analytics/contexts/Analytics";
import type {
  AllAnalyticsEventsKeys,
  TrackEventProps,
} from "@/features/Analytics/sharedTypes";

type TrackEventOnClickProps<TEventName extends AllAnalyticsEventsKeys> =
  TrackEventProps<TEventName> & {
    disabled?: boolean;
    as?: "div" | "span";
  };

export const TrackEventOnClick = <TEventName extends AllAnalyticsEventsKeys>({
  children,
  as: Wrapper = "div",
  disabled = false,
  eventName,
  eventProps = {},
}: React.PropsWithChildren<TrackEventOnClickProps<TEventName>>) => {
  const trackEvent = useTrackEvent();
  const handleTrack = React.useCallback(() => {
    if (!disabled) {
      trackEvent(eventName, eventProps);
    }
  }, [disabled, eventName, eventProps, trackEvent]);
  return <Wrapper onClick={handleTrack}>{children}</Wrapper>;
};
