export const getEnvironment = (name: string): string | undefined => {
  return process.env[name];
};

export const inE2eTest = () => !!window.brexInE2eTest;
export const inSyntheticTest = () => !!window._DATADOG_SYNTHETICS_BROWSER;
export const inBrowserTest = () => inE2eTest() || inSyntheticTest();

export const loadScript = (
  src: string,
  options?: Partial<Pick<HTMLScriptElement, "async" | "defer" | "type">>,
): Promise<string> => {
  // if script is already in the document, skip
  if (document.querySelector(`script[src="${src}"]`)) {
    return Promise.resolve(src);
  }
  const script = document.createElement("script");
  script.src = src;
  // load async by default
  script.async = options?.async ? options.async : true;
  // javascript type by default
  script.type = options?.type ? options.type : "text/javascript";
  if (options?.defer) {
    script.defer = options.defer;
  }
  return new Promise((resolve, reject) => {
    const handleLoad = () => {
      resolve(src);
    };
    const handleError = (error: ErrorEvent) => {
      reject(error);
    };
    script.addEventListener("load", handleLoad);
    script.addEventListener("error", handleError);
    document.body.appendChild(script);
  });
};

export const isStaging = getEnvironment("APP_ENV") === "stg";

export const isProduction = getEnvironment("APP_ENV") === "prod";
