export const AUTHORIZATION_STATE_KEY = "auth_flow_";
export const AUTHORIZATION_STATE_TTL_MINUTES = 30;

/**
 * Purge expired states from localStorage based on TTL.
 */
export const purgeExpiredStates = (currentTime = new Date()) => {
  const keysToRemove = Array.from({ length: localStorage.length })
    .map((_, i) => localStorage.key(i))
    .filter((key) => key?.startsWith(AUTHORIZATION_STATE_KEY))
    .filter((key) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const authState = localStorage.getItem(key!);
      if (!authState) return true;
      const { expires } = JSON.parse(authState);
      return !expires || currentTime.getTime() > new Date(expires).getTime();
    });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  keysToRemove.forEach((key) => localStorage.removeItem(key!));
};

/**
 * Save authorization state to localStorage with TTL.
 */
export const saveState = (
  state: Record<string, unknown> & { id: string },
  currentTime = new Date(),
  ttlMinutes = AUTHORIZATION_STATE_TTL_MINUTES,
) => {
  const expires = new Date(currentTime);
  expires.setMinutes(expires.getMinutes() + ttlMinutes);
  localStorage.setItem(
    `${AUTHORIZATION_STATE_KEY}${state.id}`,
    JSON.stringify({ ...state, expires }),
  );
};
