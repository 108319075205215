// This is temporary while we validate what will be our no-storage solution.
// Eventually we want to move out of this persistent approach, and into a
// rotating-leader, or use a serviceworker/webworker to centralize storage in a

type TokenExchangeResponse = {
  accessToken: string;
  idToken: string;
  expiresIn: number;
};

// sandboxed env
export const TOKEN_KEY = "BREX_AUTH_TOKEN_MANAGMENT_TOKEN" as const;

export const setToken = (token: TokenExchangeResponse) => {
  window.localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
};

export const getToken = (): TokenExchangeResponse | null => {
  const tokenItem = window.localStorage.getItem(TOKEN_KEY);
  if (!tokenItem) {
    return null;
  }
  try {
    const parsedToken = JSON.parse(tokenItem) as TokenExchangeResponse;
    return parsedToken;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const deleteToken = () => {
  window.localStorage.removeItem(TOKEN_KEY);
};

export const getCleanToken = (token: string) =>
  `${token?.slice(0, 3)}...${token?.slice(-3)}`;
