import type { IntegrationsFlags } from "@/features/Analytics/sharedTypes";

export const cookieManagerDetected = () => {
  return window.transcend;
};
export const onManageCookies = () => {
  return window?.transcend?.showConsentManager?.();
};

export const isGeofencingGDPR = () => {
  return !!window.airgap?.getRegimes?.().has("GDPR");
};

export const defaultEnabledCookiesItems: IntegrationsFlags = {
  "Amazon S3": true,
  Amplitude: false,
  "Amplitude Actions": false,
  "Google Tag Manager": false,
  "Google Universal Analytics": false,
  "Google Analytics V4": false,
  Mutiny: true,
  snowflake: false,
  "Marketo V2": false,
};

export type CookieCategoryTypes =
  | "strictlyNecessary"
  | "securityAndFraud"
  | "performance"
  | "functional"
  | "targeting"
  | "socialMedia";

export const getCookieGroups = () => {
  return window.airgap?.getConsent?.().purposes;
};

// Cookie categories. Each category is assigned a boolean to indicate
// whether or not they've been accepted by the user.
export const getCookieCategories = () => {
  const groups = getCookieGroups() || "";
  return {
    strictlyNecessary: !!groups.Functional,
    securityAndFraud: !!groups.Functional,
    performance: !!groups.SaleOfInfo,
    functional: !!groups.Functional,
    targeting: !!groups.SaleOfInfo,
    socialMedia: !!groups.SaleOfInfo,
  };
};

export const getSegmentDestinationsFromCookies = () => {
  const groups = getCookieGroups();
  if (!groups) return defaultEnabledCookiesItems;
  const categories = getCookieCategories();

  return {
    "Amazon S3": categories.strictlyNecessary,
    Amplitude: categories.performance,
    "Amplitude Actions": categories.performance,
    "Google Tag Manager": categories.performance,
    "Google Universal Analytics": categories.performance,
    "Google Analytics V4": categories.performance,
    Mutiny: categories.functional,
    snowflake: categories.performance,
    /**
     * Defaulted to false, if you want to enable marketo for certain events,
     * explicitly pass in "Marketo V2": true to the identify / track event
     */
    "Marketo V2": false,
  };
};

// Returns whether or not a given category has been accepted by the user at this time.
// If getCookieCateogires() returns undefi2ed, then by default we only return true for default enabled categories:
// strictlyNecessary, securityAndFraud, and functional.
export const isCategoryIntegrationsEnabled = (
  category: CookieCategoryTypes,
) => {
  const categories = getCookieCategories();
  if (!categories) {
    return (
      category === "strictlyNecessary" ||
      category === "securityAndFraud" ||
      category === "functional"
    );
  }
  return categories[category];
};
