import LogRocket from "logrocket";
import { getEnvironment } from "@/helpers/environment";

export const logRocketToken = getEnvironment("LOG_ROCKET_TOKEN");

export const isLogRocketEnabled =
  (process.env.APP_ENV === "prod" || process.env.NODE_ENV === "test") &&
  logRocketToken;

const logLevels = ["log", "info", "debug", "warn", "error"] as const;

type LogLevel = (typeof logLevels)[number];

const makeLogFunction =
  (logFunctionName: LogLevel) =>
  (...props: any[]) => {
    try {
      LogRocket[logFunctionName](...props);
    } catch {}
  };

/**
 * Allows to log to LogRocket but not have it appear in the browser console
 */
export const logRocketConsole = logLevels.reduce(
  (acc, logFunctionName) => ({
    ...acc,
    [logFunctionName]: makeLogFunction(logFunctionName),
  }),
  {} as Record<LogLevel, (...props: any[]) => void>,
);
