export const segmentBatchConfig = {
  "Segment.io": {
    deliveryStrategy: {
      strategy: "batching",
      config: {
        size: 100,
        timeout: 2000,
      },
    },
  },
};
