import { datadogRum } from "@datadog/browser-rum";
import { Perfume } from "perfume.js";
import { internalTrackError } from "@/helpers/errorTracking";

export const initCoreVitals = () => {
  try {
    new Perfume({
      analyticsTracker: ({
        metricName,
        data,
        navigatorInformation,
        attribution,
        rating,
        navigationType,
      }) => {
        // Recreating the objects here to raise visibility on the content
        const commonMetadata = {
          navigatorInformation,
          attribution,
          rating,
          navigationType,
        };

        switch (metricName) {
          case "navigationTiming":
            // @ts-expect-error types not properly exported
            // IPerfumeData = number | IPerfumeNavigationTiming | IPerfumeNetworkInformation;
            if (data?.timeToFirstByte) {
              datadogRum.addAction("vitals.navigationTiming", {
                data,
                ...commonMetadata,
              });
            }
            break;
          case "networkInformation":
            // @ts-expect-error types not properly exported
            // IPerfumeData = number | IPerfumeNavigationTiming | IPerfumeNetworkInformation;
            if (data?.effectiveType) {
              datadogRum.addAction("vitals.networkInformation", {
                data,
                ...commonMetadata,
              });
            }
            break;
          /**
           * Perfume provides a lot of metrics, like largest contentful paint
           * and total blocking time. These metrics exist on the RUM action.
           * Datadog RUM provides many of the same metrics independently. This means
           * two sources of truth. So, to rectify this, we consider Datadog as the
           * source of truth for metrics rather than Perfume.
           * The exception is total blocking time, a metric that only Perfume can provide.
           */
          default:
            datadogRum.addAction(metricName, {
              duration: data,
              ...commonMetadata,
            });
            break;
        }
      },
    });
  } catch (err) {
    internalTrackError({
      errorName: "Failed to initialize Vitals Performacne measurement",
      error: err as Error,
    });
  }
};
