import * as React from "react";
import type {
  AllAnalyticsMetadataKeys,
  AllMetadataSchemaMap,
  MetadataPayloadSchema,
} from "@/features/Analytics/sharedTypes";
import { useDeepMemo } from "@/hooks/useDeepMemo";

/**
 * Pass in either an object of metadata or a function that returns an object of metadata.
 * If you’re passing a function, make sure it’s memoized.
 */
type AnalyticsMetadataContextValue =
  | Partial<AllMetadataSchemaMap>
  | null
  | (() => Record<string, any>);

// TODO: migrate context to only accept memoized callback
export const AnalyticsMetadataContext =
  React.createContext<AnalyticsMetadataContextValue>(null);

export const useAnalyticsMetadataContext = () =>
  React.useContext(AnalyticsMetadataContext);

type AnalyticsMetadataContextProviderProps<
  TMetadataName extends AllAnalyticsMetadataKeys,
> = {
  children: React.ReactNode;
  /** The name of the logging metadata to provide - informs which properties will be set */
  name: TMetadataName;
  /**
   * The properties object, which will be logged under 'name', for all logs emitted inside this context.
   *
   * This will be DEEP-MEMOIZED, so for performance reasons, avoid passing in complex, deep, or frequently changing objects.
   * Avoiding deep and complex objects also helps keep the analytics schema clean.
   */
  properties: MetadataPayloadSchema<TMetadataName>;
};

/** Inject metadata into all logs emitted inside this provider */
export function AnalyticsMetadataContextProvider<
  TMetadataName extends AllAnalyticsMetadataKeys,
>({
  children,
  name,
  properties,
}: AnalyticsMetadataContextProviderProps<TMetadataName>) {
  const parentContext = useAnalyticsMetadataContext();

  const memoizedProperties = useDeepMemo(properties);

  const value = React.useMemo(() => {
    return {
      [name]: memoizedProperties,
      ...parentContext,
    };
  }, [name, memoizedProperties, parentContext]);

  return (
    <AnalyticsMetadataContext.Provider value={value}>
      {children}
    </AnalyticsMetadataContext.Provider>
  );
}
