import Cookies from "js-cookie";
import { v4 as uuid } from "uuid";
import { getCookieRootDomain } from "@/features/Analytics/helpers/cookies";
import { glanceRedactionSelector } from "@/helpers/redaction";

const visitorIdCharacterRegex = /[a-zA-Z0-9\-~_]/;

const glanceVisitorIdStorageKey = "brexGlanceVisitorId";

const escapeGlanceVisitorId = (rawId: string) =>
  [...rawId].filter((char) => visitorIdCharacterRegex.test(char)).join("");

export function getGlanceVisitorId() {
  const existingId = Cookies.get(glanceVisitorIdStorageKey);
  const id = existingId || escapeGlanceVisitorId(uuid());

  if (!existingId) {
    Cookies.set(glanceVisitorIdStorageKey, id, {
      expires: 1,
      domain: getCookieRootDomain(window.location.host),
    });
  }

  return id;
}

const glanceScriptElementId = "glance-cobrowse";

export function initializeGlance(email: string | undefined) {
  if (
    process.env.APP_ENV !== "localhost" &&
    // this prevents us from injecting glance twice
    !document.getElementById(glanceScriptElementId)
  ) {
    const site = process.env.APP_ENV === "prod" ? "production" : "staging";

    window.GLANCE_COBROWSE = {
      visitorid: getGlanceVisitorId(),
      email,
      disableLogging: site === "production",
      getMaskingDescriptor: async () => ({
        maskedelements: [glanceRedactionSelector],
        maskpage: false,
      }),
    };

    const scriptEl = document.createElement("script");
    scriptEl.id = glanceScriptElementId;
    scriptEl.charset = "UTF-8";
    scriptEl.type = "text/javascript";
    scriptEl.src = `https://www.glancecdn.net/cobrowse/CobrowseJS.ashx?group=24318&site=${site}`;
    scriptEl.setAttribute("data-groupid", "24318");
    scriptEl.setAttribute("data-site", site);
    scriptEl.setAttribute("data-ws", "www.glance.net");
    scriptEl.setAttribute("data-presence", "on");
    scriptEl.setAttribute("data-cookietype", "secure");
    document.body.appendChild(scriptEl);
  }
}
