/**
 * An object type representing all stringifiable object values.
 *
 * It is up to the consumer to determine how non-string param values are serialized and deserialized.
 */
export type ValidQueryStringObject = Record<string, string | null | undefined>;

/** Parse the `location.search` string to an object */
export const parse = (
  queryString: string,
  // TODO: remove `| undefined` when FEP-264 is fixed
): Record<string, string | undefined> => {
  const obj: Record<string, string> = {};
  if (!queryString) {
    return obj;
  }

  const params = new URLSearchParams(queryString);
  for (const [key, value] of params.entries()) {
    if (key && value) {
      obj[key] = value;
    }
  }
  return obj;
};

/** Stringify an object of query params into a string */
export const stringify = (obj: ValidQueryStringObject): string => {
  const params = new URLSearchParams();
  for (const key in obj) {
    const value = obj[key];
    if (key && typeof value === "string" && value !== "") {
      params.set(key, value);
    }
  }
  params.sort();
  return params.toString();
};
