import * as React from "react";
import useInView from "react-cool-inview";
import { useTrackEvent } from "@/features/Analytics/contexts/Analytics";
import type {
  AllAnalyticsEventsKeys,
  TrackEventProps,
  TrackFunctionType,
} from "@/features/Analytics/sharedTypes";

type ViewEventProp<TEventName extends AllAnalyticsEventsKeys> = {
  eventName: TEventName;
  eventProps?: TrackEventProps<TEventName>["eventProps"];
  elementRef: React.RefObject<HTMLElement>;
  rootRef?: React.RefObject<HTMLElement>;
  rootMargin?: IntersectionObserverInit["rootMargin"];
  track?: TrackFunctionType;
};

export const useTrackEventOnView = <TEventName extends AllAnalyticsEventsKeys>({
  eventName,
  eventProps,
  elementRef,
  rootRef,
  track: trackFn,
  rootMargin = "-100px",
}: ViewEventProp<TEventName>) => {
  const trackHook = useTrackEvent();
  const track = trackFn || trackHook;

  const onEnter = React.useCallback(() => {
    track(eventName, eventProps);
  }, [eventName, eventProps, track]);

  const { observe } = useInView({
    unobserveOnEnter: true,
    root: rootRef ? rootRef.current : undefined,
    rootMargin,
    onEnter,
  });

  React.useEffect(() => {
    observe(elementRef.current);
  }, [elementRef, observe]);
};
