import { datadogRum } from "@datadog/browser-rum";

type CookieName = "r18" | "r18-query-param" | "r18-feature-flag";

export function getCookie(name: CookieName): boolean | null {
  const cookies = document.cookie.split("; ");
  for (const cookie of cookies) {
    const [key, value] = cookie.split("=");
    if (key === name) {
      return value === "true";
    }
  }
  return null;
}

// Cookie max-age is set to 1 month
export function setCookie(name: CookieName, value: boolean) {
  document.cookie = `${name}=${value}; path=/; max-age=2628000`;
}

// This reads the r18 query param/feature flag in order to set the `r18` cookie,
// used by nginx to serve the bundle with the appropriate React version on staging.
export function reloadIfReactVersionChanged() {
  try {
    const isR18Enabled = getCookie("r18");
    const params = new URLSearchParams(window.location.search);

    // Always prioritize the r18 query param value
    const r18Param = params.get("r18");
    let shouldEnableR18 = r18Param === "true" || r18Param === "";
    if (r18Param != null) {
      if (shouldEnableR18 !== isR18Enabled) {
        setCookie("r18", shouldEnableR18);
        setCookie("r18-query-param", shouldEnableR18);
        window.location.reload();
        return true;
      }

      return false;
    }

    // Then check if query param was set already
    const storedR18Param = getCookie("r18-query-param");
    shouldEnableR18 = storedR18Param === true;
    if (storedR18Param != null) {
      if (shouldEnableR18 !== isR18Enabled) {
        setCookie("r18", storedR18Param);
        window.location.reload();
        return true;
      }

      return false;
    }

    // Finally, check the feature flag
    const storedR18FeatureFlag = getCookie("r18-feature-flag");
    shouldEnableR18 = storedR18FeatureFlag === true;
    if (storedR18FeatureFlag != null) {
      if (shouldEnableR18 !== isR18Enabled) {
        setCookie("r18", storedR18FeatureFlag);
        window.location.reload();
        return true;
      }
    }

    return false;
  } catch (error) {
    datadogRum.addError(error);
    return false;
  }
}
